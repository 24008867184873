import { isEqual } from 'utils/lodash'

export function getTermsConfig(tenantId: string): { [key: string]: string } {
  if (isEqual(tenantId, 'cd712414-e6fb-4b5f-a8c3-6fdbb7a399fd')) {
    return {
      review_send_to_review_title: 'Select recipient',
      review_btn_send_to_review: 'Share the Brief',
      review_btn_send_for_review: 'Share the Brief',
      review_type_title: 'Number of recipients to acknowledge to succeed',
      review_any_review_title: 'Single Recipient',
      review_any_review_description: 'Only one selected user needs to acknowledge',
      review_majority_title: 'Majority',
      review_majority_description: 'The majority of selected users must acknowledge',
      review_all_title: 'All',
      review_all_description: 'All selected users must acknowledge',
      review_reviewers_list: 'List of recipients',
      review_btn_reject_form: 'Reject brief',
      review_btn_approve_form: 'Acknowledge brief',
      review_btn_approve_modal: 'Acknowledge',
      review_status_tooltip_default_msg: 'Shared by {{issuerFullname}}, {{requestedAt}}',
      review_status_tooltip_approved_msg:
        '{{appName}} app has been acknowledged. It was shared by {{issuerFullname}} - {{issuerEmail}}, {{requestedAt}}.',
      reviewers_list_title: 'List of recipients',
      review_response_types_approved: 'Acknowledged',
      review_response_types_rejected: 'Rejected',
      review_status_sent_to_review: 'Shared',
      review_status_approved: 'Acknowledged',
      review_status_rejected: 'Rejected',
      review_status_draft: 'Draft',
      review_approval_modal_title: 'Acknowledge brief?',
      review_approval_modal_description: 'The brief cannot be changed after acknowledgment',
      review_btn_cancel_review: 'Cancel sharing',
      review_cancel_review_modal_title: 'Cancel sharing?',
      review_cancel_review_modal_body: 'The sharing request will be cancelled',
      review_notification_send_to_review_success: 'The brief has been shared',
      review_notification_send_to_review_error:
        'The brief has been shared but notification has not sent due to an error.',
      review_notification_cancel_review_success: 'Sharing has been successfully canceled.',
      review_notification_cancel_review_error:
        'Sharing has been successfully canceled but notification has not sent due to an error.',
      review_notification_approved: 'The brief has been acknowledged.',
      review_notification_rejected: 'The brief has been rejected.',
    }
  }

  return {
    review_send_to_review_title: 'Select reviewer',
    review_btn_send_to_review: 'Send to review',
    review_btn_send_for_review: 'Send for review',
    review_type_title: 'Amount of reviewers for approval to succeed',
    review_any_review_title: 'Single Approval',
    review_any_review_description: 'Only one selected user needs to approve',
    review_majority_title: 'Majority',
    review_majority_description: 'The majority of selected users must approve',
    review_all_title: 'All',
    review_all_description: 'All selected users must approve',
    review_reviewers_list: 'List of reviewers',
    review_btn_reject_form: 'Reject form',
    review_btn_approve_form: 'Approve form',
    review_btn_approve_modal: 'Approve',
    review_status_tooltip_default_msg: 'Sent into review by {{issuerFullname}}, {{requestedAt}}',
    review_status_tooltip_approved_msg:
      '{{appName}} app has been approved. It was sent into review by {{issuerFullname}} - {{issuerEmail}}, {{requestedAt}}.',
    reviewers_list_title: 'List of reviewers',
    review_response_types_approved: 'Approved',
    review_response_types_rejected: 'Rejected',
    review_status_sent_to_review: 'Sent to review',
    review_status_approved: 'Approved',
    review_status_rejected: 'Rejected',
    review_status_draft: 'Draft',
    review_approval_modal_title: 'Approve form?',
    review_approval_modal_description: 'The form cannot be changed after approval',
    review_btn_cancel_review: 'Cancel review',
    review_cancel_review_modal_title: 'Cancel review?',
    review_cancel_review_modal_body: 'The review request will be canceled.',
    review_notification_send_to_review_success: 'Your request has been sent for review',
    review_notification_send_to_review_error:
      'Your request has been saved but notification has not sent due to an error.',
    review_notification_cancel_review_success: 'Review has been successfully canceled.',
    review_notification_cancel_review_error:
      'Review has been successfully canceled but notification has not sent due to an error.',
    review_notification_approved: 'The request has been approved.',
    review_notification_rejected: 'The request has been rejected.',
  }
}
