import { WppSegmentedControl, WppSegmentedControlItem, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { WppButton, WppSideModal, WppSelect, WppListItem, WppTooltip } from 'app/components/common'
import styles from 'app/features/review/components/sendToReview/SendToReview.module.scss'
import ReviewHelper from 'app/features/review/services/ReviewService'
import { REVIEW_TYPES_DATA } from 'constants/review'
import useTerms from 'hooks/useTerms'
import QuestionnaireService from 'services/questionnaire/QuestionnaireService'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IReviewState from 'store/interfaces/IReviewState'
import { setSendToReviewModal } from 'store/reducers/reviewSlice'
import { SelectChangeEventDetail, WppSegmentedControlItemCustomEvent, WppSelectCustomEvent } from 'types/common/utils'
import IProjectMember from 'types/project/IProjectMember'
import { QUESTIONNAIRE_STATUS, REVIEW_TYPES } from 'types/review/enum'
import IReviewerInfo from 'types/review/IReviewerInfo'
import IReviewType from 'types/review/IReviewType'
import { find, isEmpty, isEqual, isNull, isUndefined, toLower, toString } from 'utils/lodash'

/**
 * SendToReview Modal component
 * Shows send to review button and when user clicks on the button, it will open up side modal to select reviewers
 */
const SendToReview: React.FC = (): React.ReactElement => {
  const { osApi } = useOs()
  const termsConfig = useTerms()
  const selectRef = useRef<HTMLWppSelectElement>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const { selectedReviewers, isSendToReviewModalOpen, reviewers } = useSelector<RootState, IReviewState>(
    (state: RootState) => state.reviewState,
  )
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  const [selectReviewers, setSelectReviewers] = useState<IProjectMember[]>([])

  const [reviewType, setReviewType] = useState<REVIEW_TYPES>(REVIEW_TYPES.ANY_REVIEW)
  const { questionnaire } = projectBriefState
  const { app, activeStep } = questionnaireState

  const Reviewers = reviewers.filter(
    (member: IProjectMember) => !isEqual(toLower(member.email), toLower(appContext.userEmail)),
  )

  useEffect(() => {
    if (isSendToReviewModalOpen) {
      setSelectReviewers(
        Reviewers.filter((member: IProjectMember) => {
          const reviewer: IReviewerInfo | undefined = selectedReviewers.find((reviewer: IReviewerInfo) => {
            return isEqual(toLower(member.email), toLower(reviewer.reviewerEmail))
          })
          return !isUndefined(reviewer)
        }),
      )

      if (questionnaire.approval) {
        let reviewT = find(REVIEW_TYPES_DATA, { key: questionnaire.approval.successConstraint })
        setReviewType(reviewT ? reviewT.key : REVIEW_TYPES.ANY_REVIEW)
      }
      selectRef?.current?.updateOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendToReviewModalOpen])

  if (
    !(
      (isNull(questionnaire.approval) ||
        (questionnaire.approval && isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.FAILED))) &&
      app?.isAppEditor &&
      app?.isProjectMember
    )
  )
    return <></>

  /**
   * Shows/hide send to review side modal
   * @param {boolean} isOpen
   * @returns {void}
   */
  const handleModal = (isOpen: boolean): void => {
    if (validateApp()) dispatch(setSendToReviewModal(isOpen))
  }

  /**
   * Handles send to review action
   * @returns {void}
   */
  const handleSendToReview = (): void => {
    ReviewHelper.handleSendToReview({
      accessToken: osApi.getAccessToken(),
      category: app.categories[activeStep - 1],
      approval: questionnaire.approval,
      dispatch,
      appContext,
      projectQuestionnaireId: toString(projectBriefState.questionnaire.projectQuestionnaireId),
      questionnaireState,
      reviewers: selectReviewers,
      reviewType,
      t,
      termsConfig,
    })
  }

  /**
   * Validate questionnaire app
   * @returns {boolean}
   */
  const validateApp = (): boolean => {
    return QuestionnaireService.validateApp(questionnaireState, dispatch)
  }

  /**
   * Set review type
   * @param {WppSegmentedControlItemCustomEvent<any>} event
   */
  const handleReviewType = (event: WppSegmentedControlItemCustomEvent<any>) => {
    setReviewType(event.detail.value)
  }

  return (
    <>
      <WppTooltip
        text={t('review.send_to_review.single_member')}
        component={
          <WppButton
            id="btn-send-to-review"
            disabled={isEmpty(Reviewers)}
            size="s"
            data-testid="btn-send-to-review"
            onClick={() => handleModal(true)}
          >
            {termsConfig('review_btn_send_to_review')}
          </WppButton>
        }
        showTooltip={isEmpty(Reviewers)}
      />
      {createPortal(
        <WppSideModal
          data-testid="side-modal"
          size="s"
          open={isSendToReviewModalOpen}
          onWppSideModalClose={event => {
            if (isEqual(event.detail.reason, 'escapePress')) return
            handleModal(false)
          }}
          disableOutsideClick
        >
          <h3 slot="header">{termsConfig('review_send_to_review_title')}</h3>
          <div slot="body" className={styles.sideModalBody}>
            <WppSelect
              data-testid="reviewer-change"
              onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                setSelectReviewers(event.detail.value)
              }}
              placeholder="ex. Nathalie, or james@consulting.com"
              value={selectReviewers}
              withSearch
              withFolder
              type="multiple"
              id="send-to-review-dropdown"
              ref={selectRef}
              name="send-to-review-dropdown"
            >
              {Reviewers.map((reviewer: IProjectMember) => (
                <WppListItem value={reviewer} key={reviewer.id}>
                  <p slot="label">
                    {reviewer.firstname} {reviewer.lastname}
                  </p>
                  <p slot="caption">{reviewer.email}</p>
                </WppListItem>
              ))}
            </WppSelect>
            <div className={styles.reviewTypeContainer}>
              <WppTypography type="s-strong" tag="p">
                {termsConfig('review_type_title')}
              </WppTypography>
              <WppSegmentedControl className={styles.reviewTypes} value={reviewType} size="s" width="100%">
                {REVIEW_TYPES_DATA.map((reviewT: IReviewType) => (
                  <WppSegmentedControlItem
                    onWppChangeSegmentedControlItem={handleReviewType}
                    key={reviewT.key}
                    value={reviewT.key}
                    data-testid="review-type"
                  >
                    {termsConfig(reviewT.title)}
                  </WppSegmentedControlItem>
                ))}
              </WppSegmentedControl>
              <WppTypography type="xs-midi" tag="p">
                {termsConfig((find(REVIEW_TYPES_DATA, { key: reviewType }) as IReviewType).description)}
              </WppTypography>
            </div>
          </div>
          <div slot="actions" className={styles.actions}>
            <WppButton data-testid="btn-cancel" variant="secondary" onClick={() => handleModal(false)}>
              <Trans>app.button.cancel</Trans>
            </WppButton>
            <WppButton
              disabled={isEmpty(selectReviewers)}
              data-testid="btn-send-to-review-confirm"
              onClick={() => handleSendToReview()}
            >
              {termsConfig('review_btn_send_for_review')}
            </WppButton>
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default SendToReview
