import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import styles from 'app/components/categorySection/formField/formFieldComment/FormFieldCommentIcon/FormFieldCommentIcon.module.scss'
import { WppAvatarGroup, WppIconChatMessage } from 'app/components/common'
import FieldCommentService from 'services/formField/FieldCommentService'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IFieldComment from 'types/field/fieldComment/IFieldComment'
import { QUESTIONNAIRE_STATUS } from 'types/review/enum'

interface IFormFieldCommentIconProps {
  fieldComment: IFieldComment
}

/**
 * Create icon for the field comment
 * Shows comment icon or user icon for field with number of user who commented
 * @param {object} props
 * @param {IFieldComment} props.fieldComment
 */
const FormFieldCommentIcon: React.FC<IFormFieldCommentIconProps> = ({
  fieldComment,
}: IFormFieldCommentIconProps): React.ReactElement => {
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const { enableCommentMode } = useSelector<RootState, IQuestionnaireState>(
    (state: RootState) => state.questionnaireState,
  )

  let isCommentsEmpty = _.isEmpty(fieldComment.comments)
  if (isCommentsEmpty) {
    isCommentsEmpty = _.isEmpty(fieldComment.uniqueUsers)
  }

  return (
    <div slot="trigger-element">
      {isCommentsEmpty &&
        !_.isEqual(questionnaire.approval?.status, QUESTIONNAIRE_STATUS.SUCCEEDED) &&
        enableCommentMode && <WppIconChatMessage className={styles.chatMessageIcon} />}
      {!_.isEmpty(fieldComment.uniqueUsers) && (
        <div className={styles.avatarContainer}>
          <div className={styles.avatar}>
            <div className={styles.arrow} />
            <WppAvatarGroup
              className={styles.avatarGroup}
              maxAvatarsToDisplay={2}
              size="xs"
              withTooltip
              avatars={FieldCommentService.getCommentUsers(fieldComment.uniqueUsers)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default FormFieldCommentIcon
