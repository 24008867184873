import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { WppActionButton, WppButton, WppIconChatMessage } from 'app/components/common'
import Menu from 'app/components/toolbar/toolbarAction/menu'
import styles from 'app/components/toolbar/toolbarAction/ToolbarAction.module.scss'
import Export from 'app/features/export/components'
import ReviewAction from 'app/features/review/components/action'
import SendToReview from 'app/features/review/components/sendToReview'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { APP_ACTIONS, TOAST_MESSAGE_TYPES } from 'types/app/enum'
import IApp from 'types/app/IApp'
import { QUESTIONNAIRE_STATUS } from 'types/review/enum'

interface IActionProps {
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES, app: IApp | null) => void,
    displayMessage?: boolean,
  ) => void
  handleCommentMode: () => void
}

/**
 * App action
 * Shows app header actions i.e Export as, Save Progress, Send to Review etc.
 * @param {object} props
 * @param {Function} props.handleCommentMode
 * @param {Function} props.handleAppAction
 */
const Action: React.FC<IActionProps> = ({ handleAppAction, handleCommentMode }: IActionProps): React.ReactElement => {
  const { app, enableCommentMode, activeStep } = useSelector<RootState, IQuestionnaireState>(
    (state: RootState) => state.questionnaireState,
  )
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const isAppEditor = app!.isAppEditor && app!.isProjectMember
  const isCommentMode =
    !_.isEqual(questionnaire.approval?.status, QUESTIONNAIRE_STATUS.SUCCEEDED) && app!.isProjectMember

  return (
    <>
      <Export
        app={app!}
        handleAppAction={handleAppAction}
        projectQuestionnaireId={_.toString(questionnaire.projectQuestionnaireId)}
      />
      {isCommentMode && (
        <WppActionButton
          className={classNames({ [styles.enableCommentMode]: enableCommentMode })}
          id="btn-comment-mode"
          variant="secondary"
          onClick={handleCommentMode}
        >
          <WppIconChatMessage color="var(--wpp-brand-color)" />
        </WppActionButton>
      )}
      {questionnaire.approval && <ReviewAction />}
      {!_.isEqual(app!.categories.length, activeStep) && isAppEditor && (
        <WppButton
          id="btn-save-progress"
          data-testid="btn-save-progress"
          onClick={() => handleAppAction(APP_ACTIONS.SAVE_PROGRESS, () => {})}
          variant="secondary"
          size="s"
        >
          <Trans>app.button.save_progress</Trans>
        </WppButton>
      )}
      <SendToReview />
      {_.isEqual(app!.categories.length, activeStep) && isAppEditor && (
        <WppButton
          id="btn-submit"
          data-testid="btn-submit"
          onClick={() => handleAppAction(APP_ACTIONS.SAVE_AND_EXIT, () => {})}
          className={styles.btnNextSubmit}
          size="s"
        >
          <Trans>app.button.save_and_exit</Trans>
        </WppButton>
      )}
      <Menu app={app} />
    </>
  )
}

export default Action
