import * as _ from 'lodash'
import React, { Fragment } from 'react'

import styles from 'app/components/categorySection/formBuilder/FormBuilder.module.scss'
import FormField from 'app/components/categorySection/formBuilder/formField'
import FormFieldTooltip from 'app/components/categorySection/formField/formFieldTooltip'
import { WppGrid, WppTypography } from 'app/components/common'
import IForm from 'types/form/IForm'

interface IFormBuilderProps {
  forms: IForm[]
}

/**
 * Build form
 * Shows form under the category with form name, description and fields
 * @param {object} props
 * @param {IForm[]} props.forms
 */
const FormBuilder: React.FC<IFormBuilderProps> = (props: IFormBuilderProps): React.ReactElement => {
  return (
    <>
      {props.forms.map((form: IForm) => {
        const formDescription = form.description ?? ''
        return (
          <Fragment key={form.id}>
            {!_.isEmpty(form.name) && (
              <WppGrid container fullWidth>
                <WppGrid item xl={12} xxl={12} sm={24} md={16} all={22}>
                  <div className={styles.form}>
                    <WppTypography type="m-strong" tag="p">
                      {form.name}
                    </WppTypography>
                    <div>
                      <FormFieldTooltip helpModal={form.helpModal} tooltip={form.tooltip ?? ''} />
                    </div>
                  </div>
                  {!_.isEmpty(formDescription) && (
                    <WppTypography type="s-body" tag="p">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formDescription,
                        }}
                      />
                    </WppTypography>
                  )}
                </WppGrid>
              </WppGrid>
            )}
            <WppGrid container fullWidth>
              <FormField {...props} form={form} />
            </WppGrid>
          </Fragment>
        )
      })}
    </>
  )
}

export default FormBuilder
