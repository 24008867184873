import { REVIEW_TYPES } from 'types/review/enum'
import IReviewType from 'types/review/IReviewType'

export const REVIEW_TYPES_DATA: IReviewType[] = [
  {
    key: REVIEW_TYPES.ANY_REVIEW,
    title: 'review.type.any_review.title',
    description: 'review.type.any_review.description',
  },
  {
    key: REVIEW_TYPES.MAJORITY_OF_REVIEWS,
    title: 'review.type.majority.title',
    description: 'review.type.majority.description',
  },
  {
    key: REVIEW_TYPES.ALL_REVIEWS,
    title: 'review.type.all.title',
    description: 'review.type.all.description',
  },
]
