import { WppInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/categorySection/CategorySection.module.scss'
import { WppInput } from 'app/components/common'
import { InputChangeEventDetail, InputTypes } from 'types/common/utils'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'

interface IFormFieldTextInputProps {
  field: IField
  inputType: InputTypes
  handleChange: (event: IFieldChangeEvent) => void
  id?: string
}

/**
 * Create text input field
 * @param {object} props
 * @param {IField} props.field
 * @param {InputTypes} props.inputType
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 * @param {string} props.id
 */
const FormFieldTextInput: React.FC<IFormFieldTextInputProps> = ({
  field,
  inputType,
  handleChange,
  id,
}: IFormFieldTextInputProps): React.ReactElement => {
  const { disabled, messageType, errorMessage, fieldConfig, value } = field
  const { placeholder, text } = fieldConfig

  /**
   * Handle text input change event
   * @param {WppInputCustomEvent<InputChangeEventDetail>} event
   * @returns {void}
   */
  const handleInputChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    handleChange({
      field,
      value: event.detail.value,
    })
  }

  return (
    <WppInput
      placeholder={placeholder}
      value={value}
      onWppChange={handleInputChange}
      type={inputType}
      id={id ?? `textinput-${field.id}`}
      disabled={disabled}
      messageType={messageType}
      message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
      className={_.isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage}
      name={text}
    />
  )
}

export default FormFieldTextInput
