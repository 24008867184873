import copy from 'copy-to-clipboard'
import _ from 'lodash'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styles from 'app/components/categorySection/CategorySection.module.scss'
import FormBuilder from 'app/components/categorySection/formBuilder/FormBuilder'
import { WppGrid, WppIconCopy, WppLabel, WppProgressIndicator, WppTypography } from 'app/components/common'
import IdeaBriefSummaryTemplate from 'app/templates/IdeaBrief'
import { AppDispatch, RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { showAlert } from 'store/reducers/alertSlice'
import { TOAST_MESSAGE_TYPES } from 'types/app/enum'
import ICategory from 'types/category/ICategory'

/**
 * Shows selected category with category name, description and forms
 */
const CategorySection: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const { app, activeStep } = useSelector<RootState, IQuestionnaireState>(
    (state: RootState) => state.questionnaireState,
  )
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  /**
   * Copy data to clipboard
   * @returns {void}
   */
  const onCopyToClipboard = useCallback(
    (alertMessage: string, text: string): void => {
      copy(text)
      dispatch(
        showAlert({
          message: alertMessage,
          type: TOAST_MESSAGE_TYPES.SUCCESS,
        }),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  if (!app) return <></>

  const { completionRate } = app

  const currentCategory: ICategory = app.categories[activeStep - 1]
  const projectQuestionnaireId: string = _.toString(questionnaire.projectQuestionnaireId)

  return (
    <div className={styles.container}>
      <WppGrid fullWidth container>
        <WppGrid item all={24}>
          <WppTypography className={styles.completionRate} tag="p" type="s-body">
            {app.isAppEditor && app.isProjectMember ? 'You completed ' : 'Completed '}
            <span className={styles.completionNumber}>{completionRate.completed}</span> of{' '}
            <span className={styles.completionNumber}>{completionRate.total}</span> fields.
          </WppTypography>
          <WppProgressIndicator variant="bar" value={completionRate.percentage} />
        </WppGrid>
        <WppGrid item xl={12} xxl={12} sm={24} md={16} all={22} className={styles.categoryContainer}>
          <WppTypography tag="h1" type="xl-heading">
            {currentCategory.name}
          </WppTypography>
          {!_.isEmpty(currentCategory.description) && (
            <WppTypography type="s-body" tag="p">
              <span
                dangerouslySetInnerHTML={{
                  __html: currentCategory.description,
                }}
              />
            </WppTypography>
          )}
        </WppGrid>
        <WppGrid item all={24} className={styles.formContainer}>
          {_.isEqual(_.first(app.categories)?.id, currentCategory.id) && (
            <WppGrid container fullWidth>
              <WppGrid item xl={12} xxl={12} sm={24} md={16} all={22}>
                <div className={styles.briefIDContainer}>
                  <div className={styles.briefIDLabelContainer}>
                    <WppLabel
                      typography="s-strong"
                      config={{
                        text: t('form.brief_id_number'),
                      }}
                    />
                    <WppIconCopy
                      data-testid="copy-brief-id"
                      onClick={() =>
                        onCopyToClipboard(t('action.alert_message.copy_brief_id_number'), projectQuestionnaireId)
                      }
                      className={styles.iconCopy}
                    />
                  </div>
                  <WppTypography type="s-body" tag="p">
                    {projectQuestionnaireId}
                  </WppTypography>
                </div>
              </WppGrid>
            </WppGrid>
          )}
          <FormBuilder forms={currentCategory.forms} />
        </WppGrid>
        <WppGrid item all={24}>
          {app && _.isEqual(app.appName, 'Idea Brief') && _.isEqual(currentCategory.name, 'The Summary') && (
            <IdeaBriefSummaryTemplate app={app} />
          )}
          {app && _.isEqual(app.appName, 'Idea Brief') && _.isEqual(currentCategory.name, 'Template') && (
            <IdeaBriefSummaryTemplate app={app} isEmpty />
          )}
        </WppGrid>
      </WppGrid>
    </div>
  )
}

export default CategorySection
