import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Select from 'app/components/projectBrief/select'
import IOption from 'types/common/IOption'

interface IQuestionnaireSelectionProps {
  questionnaires: IOption[]
  handleQuestionnaireSelection: (value: IOption) => void
  questionnaireId: string | null
  parentQuestionnaires: IOption[]
  handleParentQuestionnaireSelection: (value: IOption) => void
  parentQuestionnaireItemId: string | null
  disableFormSelection: boolean
}

/**
 * Shows parent and child questionnaires
 * @param {object} props
 * @param {IOption[]} props.questionnaires
 * @param {(value: IOption) => void} props.handleQuestionnaireSelection
 * @param {string | null} props.questionnaireId
 * @param {IOption[]} props.parentQuestionnaires
 * @param {(value: IOption) => void} props.handleParentQuestionnaireSelection
 * @param {string | null} props.parentQuestionnaireItemId
 * @param {boolean} props.disableFormSelection
 */
const QuestionnaireSelection: React.FC<IQuestionnaireSelectionProps> = ({
  questionnaires,
  handleQuestionnaireSelection,
  parentQuestionnaireItemId,
  handleParentQuestionnaireSelection,
  questionnaireId,
  parentQuestionnaires,
  disableFormSelection,
}: IQuestionnaireSelectionProps): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <>
      <Select
        data={questionnaires}
        onChange={handleQuestionnaireSelection}
        label={t('questionnaire.questionnaire_select.label')}
        placeholder={t('questionnaire.questionnaire_select.placeholder')}
        value={questionnaireId}
        disabled={disableFormSelection}
      />
      {!_.isEmpty(parentQuestionnaires) && (
        <Select
          data={parentQuestionnaires}
          onChange={handleParentQuestionnaireSelection}
          label={t('questionnaire.parent_select.label')}
          placeholder={t('questionnaire.parent_select.placeholder')}
          value={parentQuestionnaireItemId}
        />
      )}
    </>
  )
}

export default QuestionnaireSelection
