import _ from 'lodash'
import React from 'react'

import styles from 'app/components/categorySection/CategorySection.module.scss'
import { WppTypography } from 'app/components/common'
import FormFieldService from 'services/formField/FormFieldService'
import IField from 'types/field/IField'

interface IFormFieldReadOnlyProps {
  field: IField
  fieldType: string
}

/**
 * Create empty form field
 * @param {object} props
 * @param {IField} props.field
 * @param {string} props.fieldType
 */
const FormFieldReadOnly: React.FC<IFormFieldReadOnlyProps> = ({
  field,
  fieldType,
}: IFormFieldReadOnlyProps): React.ReactElement => {
  const { id, value, fieldConfig } = field
  const { options } = fieldConfig
  const formFieldService = new FormFieldService()

  const fieldValue = formFieldService.getReadOnlyFieldValue(value, options, fieldType)

  return (
    <WppTypography
      type="s-body"
      aria-disabled="true"
      className={_.isEqual(fieldValue.length, 0) ? styles.formReadOnlyEmpty : styles.formReadOnlyGeneral}
      id={id ?? `readonly-${field.id}`}
    >
      {fieldValue}
    </WppTypography>
  )
}

export default FormFieldReadOnly
