import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'

import commonStyles from 'app/components/categorySection/CategorySection.module.scss'
import styles from 'app/components/categorySection/formField/formFieldFileUpload/FormFieldFileUpload.module.scss'
import { WppButton, WppFileUpload, WppTypography, WppIconFolderOpen } from 'app/components/common'
import FileService from 'services/file/FileService'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'
import IFile from 'types/file/IFile'

interface IFormFieldFileUploadProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create file upload field component
 * @param {object} props
 * @param {IField} props.field
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 */
const FormFieldFileUpload: React.FC<IFormFieldFileUploadProps> = ({
  field,
  handleChange,
}: IFormFieldFileUploadProps): React.ReactElement => {
  const { errorMessage, fieldConfig, value, disabled } = field
  const { file, multi } = fieldConfig
  const fileUploadReadOnlyClass = disabled ? commonStyles.fileUploadReadOnly : ''

  const handleFileUploadChange = async (event: CustomEvent) => {
    const fileService = new FileService()

    handleChange({
      field,
      value: fileService.getInputFiles(event, value),
      hasError: event.detail.hasError,
    })
  }

  const messageType: any = field.messageType

  return (
    <>
      {file?.templateTitle && file?.templateUrl && (
        <div className={styles.downloadContainer}>
          <WppButton
            id="btn-download-template"
            onClick={() => window.open(file.templateUrl, '_blank')}
            className={styles.downloadTemplate}
          >
            {file?.templateTitle}
          </WppButton>
        </div>
      )}

      <WppFileUpload
        onWppChange={handleFileUploadChange}
        size={file?.maxSize}
        value={value.map((data: IFile) =>
          !_.isNull(data.id)
            ? data.file
            : { name: data.file.name, size: data.file.size, type: data.file.type, url: data.file.url, disabled },
        )}
        data-testid={`file-upload-${field.id}`}
        multiple={multi}
        acceptConfig={file?.accept}
        className={classNames(
          _.isEqual(messageType, 'error') ? commonStyles.errorMessage : commonStyles.customStyleDefaultMessage,
          styles.fileUpload,
          fileUploadReadOnlyClass,
        )}
        onWppFileUploadItemClick={event => {
          const UploadedFiles = value.filter((file: IFile) => !_.isNull(file.id))
          const SelectedFile: IFile | undefined = UploadedFiles.find(({ file }: IFile) =>
            _.isEqual(file.name, event.detail.name),
          )
          if (!_.isNull(SelectedFile)) {
            window.open(SelectedFile?.file.url, '_blank')
          }
        }}
        message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
        messageType={messageType}
      />
      {disabled && _.isEqual(value.length, 0) && (
        <div className={commonStyles.fileUploadReadOnlyEmpty}>
          <WppIconFolderOpen />
          <WppTypography type="s-body" className={commonStyles.fileUploadReadOnlyEmptyText}>
            <Trans>form_field_file_upload.no_files_uploaded</Trans>
          </WppTypography>
        </div>
      )}
    </>
  )
}

export default FormFieldFileUpload
