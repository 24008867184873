export enum REVIEW_RESPONSE_TYPES {
  REJECTION = 'REJECTION',
  APPROVAL = 'APPROVAL',
  PENDING = 'PENDING',
}

export enum REVIEW_ACTION_TYPES {
  SEND_TO_REVIEW = 'SEND_TO_REVIEW',
  APPROVE_REJECT_ACTION = 'APPROVE_REJECT_ACTION',
  CANCEL_REVIEW = 'CANCEL_REVIEW',
}

export enum REVIEW_TYPES {
  ALL_REVIEWS = 'ALL_REVIEWS',
  MAJORITY_OF_REVIEWS = 'MAJORITY_OF_REVIEWS',
  ANY_REVIEW = 'ANY_REVIEW',
}

// Questionnaire Status
export enum QUESTIONNAIRE_STATUS {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
}

// Review Status
export enum REVIEW_STATUS {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

// Questionnaire Status Values
export enum QUESTIONNAIRE_STATUS_VALUES {
  DRAFT = 'Draft',
  SENT_FOR_APPROVAL = 'Sent to review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

// Questionnaire status variant
export enum TAG_VARIANTS {
  NEUTRAL = 'neutral',
  WARNING = 'warning',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}
