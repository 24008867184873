import _ from 'lodash'

import { APP_EDITOR_PERMISSION } from 'constants/appConfig'
import IPermission from 'types/permission/IPermission'

export default class PermissionService {
  /**
   * Validate if the user has app editor permission
   * @param {string[]} permissions
   * @returns {boolean}
   */
  public static hasAppEditor(permissions: string[]): boolean {
    let isAppEditor = false
    permissions.forEach((permission: string) => {
      if (_.isEqual(permission, APP_EDITOR_PERMISSION)) {
        isAppEditor = true
      }
    })
    return isAppEditor
  }

  /**
   * Get logged-in user permissions
   * @param {IPermission[]} permissions
   * @returns {string[]}
   */
  public static getPermissions(permissions: IPermission[]): string[] {
    const userPermissions: string[] = []
    permissions.forEach((permission: IPermission) => {
      permission.permissions.forEach((perm: string) => {
        userPermissions.push(perm)
      })
    })
    return userPermissions
  }
}
