import _ from 'lodash'

import { ORDER_BY } from 'types/table/enum'
import ITableRow from 'types/table/ITableRow'

export default class TableService {
  /**
   * Sort table data
   * @param {ITableRow[]} rowData
   * @param {string} orderByColumn
   * @param {ORDER_BY} orderBy
   * @returns {ITableRow[]}
   */
  public static sortData(rowData: ITableRow[], orderByColumn: string, orderBy: ORDER_BY): ITableRow[] {
    return _.orderBy(rowData, (row: ITableRow) => row[orderByColumn].text, [orderBy])
  }
}
