import { AxiosError, AxiosResponse } from 'axios'
import HTTP_STATUS_CODES from 'http-status-codes'

import ReviewHelper from 'app/features/review/services/ReviewService'
import {
  getParentQuestionnairesAPIPath,
  getProjectAppsAPIPath,
  getQuestionnaireAPIPath,
  getQuestionnaireByIdAPIPath,
  getQuestionnairesAPIPath,
  getSelectedQuestionnaireAPIPath,
} from 'constants/apiPaths'
import { ERR_REQ_FIELD_APP } from 'constants/common'
import AxiosService from 'lib/AxiosService'
import CommonService from 'services/CommonService'
import FormService from 'services/form/FormService'
import FormValidationService from 'services/form/FormValidationService'
import SubmitService from 'services/form/SubmitService'
import FieldCommentService from 'services/formField/FieldCommentService'
import ProjectService from 'services/project/ProjectService'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import { setErrorAlert, showAlert } from 'store/reducers/alertSlice'
import { setApp, setError, setLoading, setState } from 'store/reducers/questionnaireSlice'
import { setReviewers } from 'store/reducers/reviewSlice'
import { STEP_ACTIONS, TOAST_MESSAGE_TYPES } from 'types/app/enum'
import IApp from 'types/app/IApp'
import ICategory from 'types/category/ICategory'
import IOption from 'types/common/IOption'
import IParentQuestionnaire from 'types/common/IParentQuestionnaire'
import IResponseApp from 'types/field/response/IResponseApp'
import IProject from 'types/project/IProject'
import IProjectApp from 'types/project/IProjectApp'
import IProjectQuestionnaire from 'types/projectQuestionnaire/IProjectQuestionnaire'
import IQuestionnaire from 'types/questionnaire/IQuestionnaire'
import IParamHandleStep from 'types/questionnaire/params/IParamHandleStep'
import { QUESTIONNAIRE_STATUS, QUESTIONNAIRE_STATUS_VALUES, TAG_VARIANTS } from 'types/review/enum'
import { cloneDeep, gt, isEmpty, isEqual, isNull, sortBy } from 'utils/lodash'

export default class QuestionnaireService {
  /**
   * Enable/Disable comment mode
   * @param {IQuestionnaireState} state
   * @param {Function} dispatch
   * @returns {void}
   */
  public static readonly handleCommentMode = (state: IQuestionnaireState, dispatch: Function): void => {
    dispatch(setState({ ...state, enableCommentMode: !state.enableCommentMode }))
  }
  /**
   * Get Questionnaires
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @returns {Promise<IOption[]>}
   */
  public static readonly getQuestionnaires = async (
    accessToken: string,
    appContext: IAppContextState,
  ): Promise<IOption[]> => {
    const { tenantId } = appContext
    const axiosService = new AxiosService(accessToken)
    const questionnaires: AxiosResponse<IQuestionnaire[]> = await axiosService.get(
      getQuestionnairesAPIPath(tenantId),
      tenantId,
    )

    return sortBy(
      CommonService.getOptions<IQuestionnaire>(questionnaires.data, 'id', 'name', 'parentQuestionnaireId'),
      ['label'],
      ['asc'],
    )
  }

  /**
   * Get Questionnaire by id
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @param {string} id
   * @returns {Promise<IQuestionnaire>}
   */
  public static readonly getQuestionnaireById = async (
    accessToken: string,
    appContext: IAppContextState,
    id: string,
  ): Promise<IQuestionnaire> => {
    const axiosService = new AxiosService(accessToken)
    const questionnaire: AxiosResponse<IQuestionnaire> = await axiosService.get(
      getQuestionnaireByIdAPIPath(id),
      appContext.tenantId,
    )
    return questionnaire.data
  }

  /**
   * Get Selected Questionnaire
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @returns {Promise<IOption[]>}
   */
  public static readonly getSelectedQuestionnaire = async (
    accessToken: string,
    appContext: IAppContextState,
  ): Promise<IProjectQuestionnaire> => {
    const { tenantId, itemId, projectId } = appContext
    const axiosService = new AxiosService(accessToken)
    const questionnaire: AxiosResponse<IProjectQuestionnaire> = await axiosService.get(
      getSelectedQuestionnaireAPIPath(projectId, itemId),
      tenantId,
    )
    return questionnaire.data
  }

  /**
   * Update category data by category id
   * @param {ICategory[]} categories
   * @param {ICategory} updatedCategory
   * @param {string} categoryId
   * @returns {ICategory[]}
   */
  public static readonly updateCategoryById = (
    categories: ICategory[],
    updatedCategory: ICategory,
    categoryId: string,
  ): ICategory[] => {
    return categories.map((categoryParam: ICategory) => {
      if (isEqual(categoryParam.id, categoryId)) {
        return updatedCategory
      }
      return categoryParam
    })
  }

  /**
   * Get Parent Questionnaires list
   * @param {IProjectApp[]} projectApps
   * @param {IParentQuestionnaire[]} parentQuestionnaires
   * @param {Function} termsConfig
   * @returns {IOption[]}
   */
  public static readonly getParentQuestionnaireOptions = (
    projectApps: IProjectApp[],
    parentQuestionnaires: IParentQuestionnaire[],
    termsConfig: Function,
  ): IOption[] => {
    let options: IOption[] = []

    for (let projectApp of projectApps) {
      for (let parentQuestionnaire of parentQuestionnaires) {
        if (isEqual(projectApp.itemId, parentQuestionnaire.itemId)) {
          const approvalData = ReviewHelper.getApprovalData(parentQuestionnaire.approval)
          options.push({
            id: parentQuestionnaire.itemId,
            label: projectApp.location.application.name,
            subLabel: projectApp.location.phase.name,
            tag: {
              label: approvalData
                ? termsConfig(approvalData.statusStr)
                : termsConfig(QUESTIONNAIRE_STATUS_VALUES.DRAFT),
              variant: approvalData ? approvalData.statusVariant : TAG_VARIANTS.NEUTRAL,
            },
          })
        }
      }
    }
    return options
  }

  /**
   * Get Parent Questionnaires
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} questionnaireId
   * @param {string} projectId
   * @param {Function} termsConfig
   * @returns {Promise<IOption[]>}
   */
  public static readonly getParentQuestionnaires = async (
    accessToken: string,
    tenantId: string,
    parentQuestionnaireId: string,
    projectId: string,
    termsConfig: Function,
  ): Promise<IOption[]> => {
    const axiosService = new AxiosService(accessToken)
    const parentQuestionnaires: AxiosResponse<IParentQuestionnaire[]> = await axiosService.get(
      getParentQuestionnairesAPIPath(parentQuestionnaireId, projectId),
      tenantId,
    )

    let options: IOption[] = []
    if (!isEmpty(parentQuestionnaires.data)) {
      const projectApps: AxiosResponse<IProjectApp[]> = await axiosService.post(
        getProjectAppsAPIPath(projectId),
        {},
        tenantId,
      )

      options = QuestionnaireService.getParentQuestionnaireOptions(
        projectApps.data,
        parentQuestionnaires.data,
        termsConfig,
      )
    }
    return options
  }

  /**
   * Validate all fields under all categories
   * @param {IQuestionnaireState} questionnaire
   * @param {Function} showAlert
   * @param {Function} dispatch
   * @param {Function} errorMessage
   * @returns {void}
   */
  public static readonly validateApp = (
    questionnaire: IQuestionnaireState,
    dispatch: Function,
    errorMessage: string = ERR_REQ_FIELD_APP,
  ): boolean => {
    const { app } = questionnaire

    if (!app) return false

    const formValidationService = new FormValidationService()
    const updatedApp = formValidationService.validateAppByCategoryId(app)

    if (!updatedApp.isValid) {
      dispatch(
        showAlert({
          message: errorMessage,
          type: TOAST_MESSAGE_TYPES.ERROR,
        }),
      )
      const updatedState: IQuestionnaireState = { ...questionnaire, app: updatedApp }
      dispatch(setState(updatedState))
      return false
    }
    return true
  }

  /**
   * Save data on step (category) change
   */
  private static readonly saveStep = async ({
    accessToken,
    appContext,
    questionnaireState,
    app,
    projectQuestionnaireId,
    dispatch,
    showAlert,
    currentCategory,
    nextCategory,
  }: {
    accessToken: string
    appContext: IAppContextState
    questionnaireState: IQuestionnaireState
    app: IApp
    projectQuestionnaireId: string
    nextCategory: ICategory
    dispatch: Function
    showAlert: Function
    currentCategory: ICategory
  }) => {
    let updatedApp = app
    const formService = new FormService()
    let prevCategory: ICategory = currentCategory

    dispatch(setLoading(true))
    prevCategory = await this.onSaveProgress(
      accessToken,
      appContext,
      questionnaireState,
      projectQuestionnaireId,
      prevCategory,
      dispatch,
    )

    updatedApp = formService.updateCategoryStatus(updatedApp, prevCategory.id, false)
    updatedApp = {
      ...updatedApp,
      categories: this.updateCategoryById(updatedApp.categories, { ...prevCategory, touched: true }, prevCategory.id),
    }
    updatedApp = await FieldCommentService.updateCommentForCategory(
      accessToken,
      appContext.tenantId,
      projectQuestionnaireId,
      updatedApp,
      nextCategory.id,
      showAlert,
    )
    const appStatus = {
      ...questionnaireState.appStatus,
      isAppTouched: false,
    }

    dispatch(
      setState({
        ...questionnaireState,
        appStatus,
        loading: false,
        app: {
          ...updatedApp,
          currentCategoryId: nextCategory.id,
        },
        activeStep: nextCategory.categoryIndex,
      }),
    )
  }

  /**
   * Handle Stepper change
   */
  public static readonly handleStep = async ({
    accessToken,
    appContext,
    questionnaireState,
    projectQuestionnaireId,
    stepNumber,
    dispatch,
    stepAction,
    nextCategory,
    t,
  }: IParamHandleStep) => {
    const { app, activeStep } = questionnaireState
    try {
      if (!app) {
        return
      }

      if (gt(stepNumber, app.categories.length)) {
        return
      }

      if (isEqual(stepNumber, 0) && isEqual(stepAction, STEP_ACTIONS.PREVIOUS)) {
        return
      }

      if (isEqual(stepAction, STEP_ACTIONS.STEP) && isEqual(nextCategory.id, app.currentCategoryId)) {
        return
      }

      const formValidationService = new FormValidationService()
      let updatedApp = formValidationService.validateAppByCategoryId(app, app.currentCategoryId)
      let currentCategory: ICategory = updatedApp.categories[activeStep - 1]

      if (!currentCategory.isValid && app.isAppEditor) {
        dispatch(
          showAlert({
            message: ERR_REQ_FIELD_APP,
            type: TOAST_MESSAGE_TYPES.ERROR,
          }),
        )
        dispatch(
          setState({
            ...questionnaireState,
            app: updatedApp,
          }),
        )
        return
      }

      if (isEqual(stepAction, STEP_ACTIONS.STEP) && app.isAppEditor) {
        updatedApp = formValidationService.validatePreviousCategories(app, nextCategory)
      }

      await this.saveStep({
        accessToken,
        app: cloneDeep(updatedApp),
        dispatch,
        appContext,
        projectQuestionnaireId,
        questionnaireState,
        currentCategory,
        nextCategory,
        showAlert,
      })
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))

      const err = error as AxiosError
      const errorMessage: string = isEqual(err.response?.status, HTTP_STATUS_CODES.FORBIDDEN)
        ? t('questionnaire.error.unauthorized_save')
        : t('questionnaire.error.save')
      dispatch(setErrorAlert(errorMessage))
    }
  }

  /**
   * Save form data
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @param {IQuestionnaireState} questionnaireState
   * @param {string} projectQuestionnaireId
   * @param {ICategory} category
   * @param {Function} dispatch
   * @return {Promise<ICategory>}
   */
  public static readonly onSaveProgress = async (
    accessToken: string,
    appContext: IAppContextState,
    { app }: IQuestionnaireState,
    projectQuestionnaireId: string,
    category: ICategory,
    dispatch: Function,
  ): Promise<ICategory> => {
    if (!app?.isAppEditor) {
      return category
    }

    const { itemId, projectId, tenantId, homeUrl } = appContext
    if (!app || !category.dirty) {
      return category
    }

    const submitService = new SubmitService()

    const formService = new FormService()
    let updatedCategory = await formService.preSaveCheck(category, accessToken, tenantId, homeUrl, projectId)
    const FormData = submitService.onSaveProgress(updatedCategory)
    await submitService.saveToDb(accessToken, itemId, projectId, tenantId, FormData, projectQuestionnaireId)

    updatedCategory = FormService.updateCategoryFieldInitialValue(updatedCategory)

    dispatch(
      setApp({
        ...app,
        categories: this.updateCategoryById(app.categories, updatedCategory, updatedCategory.id),
      }),
    )
    return updatedCategory
  }

  /**
   * Load data when questionnaire components mount
   * @param {string} accessToken
   * @param {IAppContextState} appContext
   * @param {IQuestionnaireState} questionnaire
   * @param {IProjectBriefState} projectBriefState
   * @param {string} projectQuestionnaireId
   * @param {Function} dispatch
   * @return {Promise<ICategory>}
   */
  public static readonly initLoad = async (
    accessToken: string,
    appContext: IAppContextState,
    questionnaire: IQuestionnaireState,
    projectBriefState: IProjectBriefState,
    projectQuestionnaireId: string,
    dispatch: Function,
  ): Promise<void> => {
    const { projectId, tenantId, userEmail } = appContext
    try {
      if (!projectId) {
        return
      }
      dispatch(
        setState({
          ...questionnaire,
          activeStep: 1,
          app: null,
        }),
      )

      const axiosService = new AxiosService(accessToken)

      const response: AxiosResponse<IResponseApp> = await axiosService.get(
        getQuestionnaireAPIPath(projectQuestionnaireId),
        tenantId,
      )

      const projectInfo: IProject | null = await ProjectService.getProjectInfo(
        accessToken,
        projectId,
        tenantId,
        userEmail,
      )

      if (!isNull(projectInfo)) {
        dispatch(setReviewers(projectInfo.members))
      }

      let isAppEditable = isNull(projectBriefState.questionnaire.approval)
        ? true
        : isEqual(projectBriefState.questionnaire.approval?.status, QUESTIONNAIRE_STATUS.FAILED)

      const formService = new FormService()
      const UpdatedApp: IApp = await formService.getApp({
        accessToken,
        appName: appContext.appName,
        isAppEditable,
        isProjectMember: !isNull(projectInfo),
        permissions: appContext.permissions,
        projectId: projectId,
        projectQuestionnaireId,
        showAlert,
        tenantId: tenantId,
        app: response.data,
      })

      dispatch(setApp(UpdatedApp))
    } catch {
      dispatch(setError())
    }
  }
}
