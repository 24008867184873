import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/categorySection/formField/formFieldCheckbox/FormFieldCheckbox.module.scss'
import FormFieldChildren from 'app/components/categorySection/formField/formFieldChildren'
import FormFieldMessage from 'app/components/categorySection/formField/formFieldMessage'
import FormFieldRadioCheckLabel from 'app/components/categorySection/formField/formFieldRadioCheckLabel'
import { WppCheckbox } from 'app/components/common'
import IOption from 'types/common/IOption'
import { FIELD_ORIENTATION } from 'types/field/enum'
import IField from 'types/field/IField'
import IFieldChangeEvent from 'types/field/IFieldChangeEvent'

interface IFormFieldCheckboxProps {
  field: IField
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create checkbox field
 * @param {object} props
 * @param {IField} props.field
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 */
const FormFieldCheckbox: React.FC<IFormFieldCheckboxProps> = ({
  field,
  handleChange,
}: IFormFieldCheckboxProps): React.ReactElement => {
  const { disabled, value, isRequired } = field
  const { orientation, options, text } = field.fieldConfig
  const Options = value.map((option: IOption) => option.id)

  /**
   * Handles change event for checkbox
   * @param {IOption} option
   * @returns {void}
   */
  const onChange = (option: IOption): void => {
    const Value = !_.isEqual(Options.indexOf(option.id), -1)
      ? value.filter((val: IOption) => !_.isEqual(val.id, option.id))
      : _.uniqBy([...value, option], 'id')

    handleChange({
      field,
      value: Value,
    })
  }

  return (
    <>
      <div
        className={classNames(
          {
            [styles.displayVertical]: _.isEqual(orientation, FIELD_ORIENTATION.VERTICAL),
            [styles.displayHorizontal]: _.isEqual(orientation, FIELD_ORIENTATION.HORIZONTAL),
          },
          styles.container,
        )}
      >
        {options.map((option: IOption, index: number) => (
          <div key={option.id}>
            <div className={styles.checkboxContainer}>
              <WppCheckbox
                key={option.id}
                id={`checkbox-${field.id}-${index + 1}`}
                disabled={disabled}
                required={isRequired}
                checked={!_.isEqual(Options.indexOf(option.id), -1)}
                onWppChange={() => onChange(option)}
                name={text}
              />
              <FormFieldRadioCheckLabel
                id={`${field.id}-${option.id}`}
                text={option.label}
                helpModal={option.help_model}
                disabled={field.disabled}
                handleChange={() => onChange(option)}
              />
            </div>
            <FormFieldChildren
              field={{
                ...field,
                value,
              }}
              optionValue={option.id}
            />
          </div>
        ))}
      </div>
      <FormFieldMessage field={field} />
    </>
  )
}

export default FormFieldCheckbox
