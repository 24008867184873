import { useOs } from '@wpp-open/react'
import HTTP_STATUS_CODES from 'http-status-codes'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  Table,
  WppButton,
  WppDivider,
  WppFullScreenModal,
  WppInlineMessage,
  WppTooltip,
  WppTypography,
} from 'app/components/common'
import { NotFoundError } from 'app/components/common/errorComponents'
import { getColumnData, getRowData } from 'app/features/review/components/reviewers/reviewersList/Helper'
import styles from 'app/features/review/components/reviewers/reviewersList/ReviewersList.module.scss'
import ReviewHelper from 'app/features/review/services/ReviewService'
import { REVIEW_TYPES_DATA } from 'constants/review'
import { DEFAULT_PAGINATOR_DATA } from 'constants/table'
import useTerms from 'hooks/useTerms'
import TableHelper from 'services/table/TableService'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IReviewState from 'store/interfaces/IReviewState'
import { setViewStatusModal } from 'store/reducers/reviewSlice'
import IRTQuestionnaireReviewer from 'types/review/returnTypes/IRTQuestionnaireReviewer'
import { ORDER_BY } from 'types/table/enum'
import ITable from 'types/table/ITable'
import { find, isEqual, toString } from 'utils/lodash'

/**
 * Reviewers List component
 */
const ReviewersList: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const termsConfig = useTerms()
  const { t } = useTranslation()
  const { osApi } = useOs()
  const projectBriefState = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const { isViewStatusModalOpen, selectedReviewers } = useSelector<RootState, IReviewState>(
    (state: RootState) => state.reviewState,
  )

  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const [error, setError] = useState<boolean>(false)
  const [table, setTable] = useState<ITable>({
    colDefs: getColumnData(t),
    rowData: getRowData(selectedReviewers, projectBriefState.questionnaire.approval?.requestedAt),
    paginator: DEFAULT_PAGINATOR_DATA,
    orderBy: ORDER_BY.DESC,
    loading: true,
    orderByColumn: 'date',
    hasMore: false,
  })

  /**
   * Sort reviewer table data
   * @param {ORDER_BY} orderBy
   * @param {string} orderByColumn
   * @returns {void}
   */
  const onColumnOrderChange = (orderBy: ORDER_BY, orderByColumn: string): void => {
    setTable((prevState: ITable) => ({
      ...prevState,
      rowData: TableHelper.sortData(prevState.rowData, orderByColumn, orderBy),
      orderByColumn,
      orderBy: orderBy,
    }))
  }

  /**
   * Fetch reviewers data for the questionnaire
   * @returns {Promise<void>}
   */
  const fetchReviewers = async (): Promise<void> => {
    setTable((prevState: ITable) => ({
      ...prevState,
      loading: true,
    }))

    const reviewUpdatedAt = projectBriefState.questionnaire.approval?.updatedAt ?? null
    const data: IRTQuestionnaireReviewer = await ReviewHelper.getQuestionnaireReviewers({
      accessToken: osApi.getAccessToken(),
      reviewUpdatedAt,
      tenantId: appContext.tenantId,
      dispatch,
      projectQuestionnaireId: toString(projectBriefState.questionnaire.projectQuestionnaireId),
      t,
    })

    if (data && isEqual(data.status, HTTP_STATUS_CODES.NOT_FOUND)) {
      setError(true)
      return
    }
    setTable((prevState: ITable) => ({
      ...prevState,
      rowData: TableHelper.sortData(
        getRowData(data.reviewers, projectBriefState.questionnaire.approval?.requestedAt),
        prevState.orderByColumn,
        prevState.orderBy,
      ),
      loading: false,
    }))
  }

  useEffect(() => {
    if (isViewStatusModalOpen) {
      fetchReviewers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewStatusModalOpen])

  // Shows the success constraint for the review i.e Majority, Single Approval, or All
  const successConstraint = projectBriefState.questionnaire.approval
    ? find(REVIEW_TYPES_DATA, {
        key: projectBriefState.questionnaire.approval?.successConstraint,
      })
    : null

  return (
    <>
      {createPortal(
        <WppFullScreenModal open={isViewStatusModalOpen}>
          <div slot="header" className={styles.modelHeader}>
            <WppTypography tag="h1" type="2xl-heading">
              {termsConfig('reviewers_list_title')}
            </WppTypography>
            <WppDivider />
          </div>
          <div slot="body" className={styles.modalBody}>
            {projectBriefState.questionnaire.approval && (
              <div className={styles.requesterInfo}>
                <WppTypography type="s-body">
                  Request sent by{' '}
                  <span className={styles.requesterName}>
                    {projectBriefState.questionnaire.approval.issuerFullname}
                  </span>{' '}
                  on {projectBriefState.questionnaire.approval.requestedAt}
                </WppTypography>
                {!error && successConstraint && (
                  <>
                    <WppDivider vertical />
                    <WppTooltip
                      text={termsConfig(successConstraint.description)}
                      component={
                        <WppInlineMessage
                          size="s"
                          message={termsConfig(successConstraint.title)}
                          className={styles.inlineMessage}
                          type="information"
                        />
                      }
                    />
                  </>
                )}
              </div>
            )}
            {error ? (
              <NotFoundError
                title={t('review.reviewers_list.not_found.title')}
                description={t('review.reviewers_list.not_found.description')}
              />
            ) : (
              <Table table={table} onColumnOrderChange={onColumnOrderChange} />
            )}
            <div className={styles.modalActions}>
              <WppButton
                className={styles.btnClose}
                variant="primary"
                size="m"
                data-testid="btn-close"
                onClick={() => dispatch(setViewStatusModal(false))}
              >
                <Trans>app.button.close</Trans>
              </WppButton>
            </div>
          </div>
        </WppFullScreenModal>,
        document.body,
      )}
    </>
  )
}

export default ReviewersList
