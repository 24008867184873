import _ from 'lodash'
import React from 'react'

import styles from 'app/components/categorySection/formField/formFieldMessage/FormFieldMessage.module.scss'
import { WppInlineMessage } from 'app/components/common'
import IField from 'types/field/IField'

interface IFormFieldMessageProps {
  field: IField
}

/**
 * Shows validations error for the field
 * @param {object} props
 * @param {IField} props.field
 */
const FormFieldMessage: React.FC<IFormFieldMessageProps> = ({ field }: IFormFieldMessageProps): React.ReactElement => {
  const { messageType, errorMessage, fieldConfig } = field

  return (
    <WppInlineMessage
      size="s"
      type={messageType}
      showTooltipFrom={200}
      className={_.isEqual(messageType, 'error') ? styles.errorMessage : styles.customStyleDefaultMessage}
      message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
    />
  )
}
export default FormFieldMessage
