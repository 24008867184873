import { useOs } from '@wpp-open/react'
import classNames from 'classnames'
import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { UserAvatar, WppActionButton, WppButton, WppDivider, WppInlineMessage, WppModal } from 'app/components/common'
import Spinner from 'app/components/common/spinner/Spinner'
import styles from 'app/features/review/components/selectedReviewer/SelectedReviewer.module.scss'
import ReviewService from 'app/features/review/services/ReviewService'
import FormFieldValidationService from 'services/formField/FormFieldValidationService'
import ProjectService from 'services/project/ProjectService'
import QuestionnaireService from 'services/questionnaire/QuestionnaireService'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IOption from 'types/common/IOption'
import IField from 'types/field/IField'
import IProject from 'types/project/IProject'
import ISelectedReviewerState from 'types/review/ISelectedReviewerState'

interface ISelectedReviewerProps {
  field: IField
}

/**
 * Selected Reviewers
 * Shows button next to field and when user clicks on button then it will set reviewers based on field value
 * @param {object} props
 * @param {IField} props.field
 */
const SelectedReviewer: React.FC<ISelectedReviewerProps> = ({ field }: ISelectedReviewerProps): React.ReactElement => {
  const { t } = useTranslation()
  const { osApi } = useOs()
  const [state, setState] = useState<ISelectedReviewerState>({
    loading: false,
    open: false,
    unauthorizedReviewers: [],
    unauthorizedMessage: '',
  })
  const { projectId, tenantId, userEmail } = useSelector<RootState, IAppContextState>(
    (state: RootState) => state.appContext,
  )

  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  const dispatch = useDispatch<AppDispatch>()

  /**
   * Handles selected reviewers action
   * @returns {Promise<void>}
   */
  const selectReviewers = async (): Promise<void> => {
    if (!QuestionnaireService.validateApp(questionnaireState, dispatch) || !_.isArray(field.value)) {
      return
    }

    setState((prevState: ISelectedReviewerState) => ({ ...prevState, loading: true }))
    const project: IProject | null = await ProjectService.getProjectInfo(
      osApi.getAccessToken(),
      projectId,
      tenantId,
      userEmail,
    )
    setState((prevState: ISelectedReviewerState) => ({ ...prevState, loading: false }))
    if (project) {
      const updatedState: ISelectedReviewerState = ReviewService.verifySelectedReviewer(
        project.members,
        field.value,
        userEmail,
        { ...state, loading: false },
        dispatch,
      )
      setState((prevState: ISelectedReviewerState) => ({ ...prevState, ...updatedState }))
    }
  }

  const { open, loading, unauthorizedReviewers, unauthorizedMessage } = state

  return (
    <>
      {loading && <Spinner />}
      <WppActionButton
        className={classNames(styles.btnSetReviewerAction, {
          [styles.btnSetReviewerAction__comments]: !_.isEmpty(field.fieldComment.uniqueUsers),
          [styles.btnSetReviewerAction__commentIcon]:
            questionnaireState.enableCommentMode && _.isEmpty(field.fieldComment.uniqueUsers),
        })}
        data-testid="btn-set-reviewer"
        onClick={() => selectReviewers()}
        disabled={FormFieldValidationService.isEmptyField(field)}
      >
        <Trans>app.button.set_as_approver</Trans>
      </WppActionButton>
      <WppModal open={open} size="m" className={styles.modal}>
        <h3 slot="header" className={styles.header}>
          <Trans>review.selected_reviewer.unauthorized_modal.header</Trans>
        </h3>
        <div slot="body" className={styles.body}>
          <WppInlineMessage message={t(unauthorizedMessage)} size="m" type="warning" />
          <div className={styles.unauthorizedReviewers}>
            {unauthorizedReviewers.map((option: IOption, index: number) => (
              <Fragment key={option.id}>
                <UserAvatar
                  className={styles.userAvatar}
                  email={option.subLabel}
                  fullName={option.label}
                  avatarSize="s"
                  avatarUrl={option.avatarUrl ?? ''}
                />
                {!_.isEqual(index, unauthorizedReviewers.length - 1) && <WppDivider />}
              </Fragment>
            ))}
          </div>
        </div>
        <div slot="actions" className={styles.actions}>
          <WppButton
            variant="destructive"
            data-testid="btn-close"
            size="s"
            onClick={() => setState((prevState: ISelectedReviewerState) => ({ ...prevState, open: false }))}
          >
            <Trans>app.button.close</Trans>
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}

export default SelectedReviewer
